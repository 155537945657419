import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      // Set rems
      font-size: 100%; // 1rem = 9px
      @media ( ${props => props.theme.breakpoints.sm} ) {
          font-size: 100%; // 1rem = 9px
      }
      @media ( ${props => props.theme.breakpoints.md} ) {
          font-size: 110%; // 1rem = 10px
      }
      @media ( ${props => props.theme.breakpoints.lg} ) {
          font-size: 110%; // 1rem = 10px
      }
      * { box-sizing: border-box; }
  }

  body {
      margin: 0;
      font-family: ${props => props.theme.typography.fontFamily};
      color: ${props => props.theme.colors.black};
      background-color: ${props => props.theme.colors.white};
  }

  h1, h2, h3, h4, h5, h6 {
      font-family: rift-soft, Helvetica, Arial, sans-serif; 
      font-style: normal;
      line-height: 100%;
  }
  p,
  ol, ul, li,
  code, kbd, pre, samp {
      line-height: 1.5;
      margin-top: 1rem;
      margin-bottom: 1rem;
  }

  h1, h2, h3, h4, h5, h6 {
      font-weight: bold;
      a { text-decoration: none; }
      a:hover { text-decoration: none; }
  }

  h1 { ${props => props.theme.typeStyles( 3 )}; }
  h2 { ${props => props.theme.typeStyles( 2 )}; }
  h3 { ${props => props.theme.typeStyles( 1 )}; }
  h4 { ${props => props.theme.typeStyles( 1 )}; }
  h5 { ${props => props.theme.typeStyles( 0 )}; }
  h6 { ${props => props.theme.typeStyles( 0 )}; }
  p, ul, ol, blockquote, footer { ${props => props.theme.typeStyles( 0 )}; }


  .black                { color: ${props => props.theme.colors.black} !important;        }
  .white                { color: ${props => props.theme.colors.white} !important;        }
  .gray                 { color: ${props => props.theme.colors.gray} !important;         }
  .light-gray           { color: ${props => props.theme.colors.lightGray} !important;    }
  .purple               { color: ${props => props.theme.colors.purple} !important;       }
  .fuchsia              { color: ${props => props.theme.colors.fuchsia} !important;      }
  .dark-fuchsia         { color: ${props => props.theme.colors.darkFuschia} !important;  }
  .green                { color: ${props => props.theme.colors.green} !important;        }
  .light-purple         { color: ${props => props.theme.colors.lightPurple} !important;  }
  .yellow               { color: ${props => props.theme.colors.yellow} !important;       }
  .orange               { color: ${props => props.theme.colors.orange} !important;       }
  .teal                 { color: ${props => props.theme.colors.teal} !important;         }
  .blue                 { color: ${props => props.theme.colors.blue} !important;         }
  .light-green          { color: ${props => props.theme.colors.lightGreen} !important;   }
  .dark-blue            { color: ${props => props.theme.colors.darkBlue} !important;     }
 
  .color-about          { color: ${ props => props.theme.colors.purple};                 }
  .color-leaders        { color: ${ props => props.theme.colors.fuchsia};                }
  .color-allies         { color: ${ props => props.theme.colors.green};                  }
  .color-advocates      { color: ${ props => props.theme.colors.green};                  }
  .color-events         { color: ${ props => props.theme.colors.lightPurple};            }
  .color-training       { color: ${ props => props.theme.colors.yellow};                 }
  .color-research       { color: ${ props => props.theme.colors.orange};                 }
  .color-incubators     { color: ${ props => props.theme.colors.teal};                   }
  .color-partners       { color: ${ props => props.theme.colors.blue};                   }
  .color-concept-school { color: ${ props => props.theme.colors.lightGreen};             }
  .color-academy        { color: ${ props => props.theme.colors.darkBlue};               }

  .bg-black             { background-color: ${props => props.theme.colors.black};        }
  .bg-white             { background-color: ${props => props.theme.colors.white};        }
  .bg-gray              { background-color: ${props => props.theme.colors.gray};         }
  .bg-light-gray        { background-color: ${props => props.theme.colors.lightGray};    }
  .bg-purple            { background-color: ${props => props.theme.colors.purple};       }
  .bg-fuchsia           { background-color: ${props => props.theme.colors.fuchsia};      }
  .bg-dark-fuchsia      { background-color: ${props => props.theme.colors.darkFuchsia};  }
  .bg-green             { background-color: ${props => props.theme.colors.green};        }
  .bg-light-purple      { background-color: ${props => props.theme.colors.lightPurple};  }
  .bg-yellow            { background-color: ${props => props.theme.colors.yellow};       }
  .bg-orange            { background-color: ${props => props.theme.colors.orange};       }
  .bg-teal              { background-color: ${props => props.theme.colors.teal};         }
  .bg-blue              { background-color: ${props => props.theme.colors.blue};         }
  .bg-light-green       { background-color: ${props => props.theme.colors.lightGreen};   }
  .bg-dark-blue         { background-color: ${props => props.theme.colors.darkBlue};     }

  .bg-color-about       { background-color: ${ props => props.theme.colors.purple};      }
  .bg-color-leaders     { background-color: ${ props => props.theme.colors.fuchsia};     }
  .bg-color-allies      { background-color: ${ props => props.theme.colors.green};       }
  .bg-color-advocates   { background-color: ${ props => props.theme.colors.green};       }
  .bg-color-events      { background-color: ${ props => props.theme.colors.lightPurple}; }
  .bg-color-training    { background-color: ${ props => props.theme.colors.yellow};      }
  .bg-color-research    { background-color: ${ props => props.theme.colors.orange};      }
  .bg-color-incubators  { background-color: ${ props => props.theme.colors.teal};        }
  .bg-color-partners    { background-color: ${ props => props.theme.colors.blue};        }

  .bg-white * { 
    color: ${ props => props.theme.colors.purple} !important; 
  }

  .bg-white p { 
    color: ${ props => props.theme.colors.black} !important; 
  }

  footer {
    color: ${props => props.theme.colors.gray};
  }

  li {
      ${props => props.theme.typeStyles( 0 )};
      margin-top: 0;
      margin-bottom: 0;
  }

  .caps {
    ${props => props.theme.typeStyles( -1 )};
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    font-weight: normal;
    font-family: Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }

  small {  ${props => props.theme.typeStyles( -1 )} }

  // Map colors
  ${props => Object.keys(props.theme.colors).map(key => 
    `.${key} { 
      color: ${props.theme.colors[key]}; 
    } 
    .${key}-bg { 
      background-color: ${props.theme.colors[key]}; 
    }
    `).join("")
  }
  
  code, kbd, pre, samp {
      font-family: monospace;
      font-size: 16px;
      white-space: normal;
  }
  
  pre { font-size: 16px; }
  
  ul {
      padding-left: 4rem;
      list-style-type: disc;
  }
  
  ol {
      padding-left: 4rem;
      list-style-type: decimal;
  }
  
  video {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
  
  em, i { font-style: italic; }
  
  strong, b { font-weight: bold; }
  
  blockquote {
      font-weight: bold;
      padding-left: 4rem;
  }
  
  a { color: ${props => props.theme.colors.link}; }
  a:visited { color: ${props => props.theme.colors.visited}; }
  a:hover { color: ${props => props.theme.colors.link}; }
  a:active,
  a:focus {
      color: ${props => props.theme.colors.focus};
      outline: 1px dotted ${props => props.theme.colors.focus};
  }
  
  sup, sub {
      vertical-align: baseline;
      position: relative;
      top: -0.4em;
  }
  
  sub { top: 0.4em; }
  
  label {
      ${props => props.theme.typeStyles( -1 )};
      line-height: 1.2;
      font-weight: normal;
  }
  
  .text-input,
  input[type="text"],
  input[type="password"],
  input[type="date"],
  input[type="datetime-local"],
  input[type="email"],
  input[type="month"],
  input[type="number"],
  input[type="range"],
  input[type="search"],
  input[type="tel"],
  input[type="time"],
  input[type="url"],
  input[type="week"],
  textarea {
      display: block;
      font-family:inherit;
      ${props => props.theme.typeStyles( 0 )};
      padding: 0.4rem 1rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
      border: 1px ${props => props.theme.colors.black} solid;
      border-radius: 2px;
      line-height: 1.6;
      background-color: transparent;
      -webkit-appearance: none;
      &:focus {
          border: 1px ${props => props.theme.colors.focus} solid;
      }
  }
  
  .file-input,
  input[type="file"],
  .radio-input,
  input[type="radio"],
  .checkbox-input,
  input[type="checkbox"],
  select {
      font-family:inherit;
  }
  
  .button,
  button,
  input[type="submit"],
  input[type="button"],
  input[type="reset"] {
      display: inline-block;
  
      padding: 0 2rem;
  
      font-family: rift-soft, Helvetica, Arial, sans-serif;
      font-weight: bold;
      color: ${props => props.theme.colors.link};
      ${props => props.theme.typeStyles( 0 )};
      line-height: 2;
      white-space: nowrap;
      text-overflow: ellipsis;
  
      color: ${props => props.theme.colors.link};
      background-color: transparent;
  
      &:focus {
          color: ${props => props.theme.colors.focus};
          outline: 1px dotted ${props => props.theme.colors.focus};
      }
      &:hover {
          text-decoration: none;
      }
      &:visited {
          text-decoration: none;
          color: ${props => props.theme.colors.visited};
      }
      &:active {
          text-decoration: none;
          color: inherit;
      }
  }

  @media screen and (min-width: 960px) {
    .button-large {
      font-size: 2rem;
      color: ${props => props.theme.colors.purple} !important;
      &:hover {
        background-color: ${props => props.theme.colors.purple} !important;
        color: white !important;
      }
    }
  }
  
  .errorMsg {
      color: ${props => props.theme.colors.error};
  }

  /**
   * Navbar style customization.
   */
  .uk-navbar-nav > li > a,
  .uk-navbar-dropdown-nav > li > a,
  a.uk-navbar-toggle {

    // Remove padding from the a tag, so we can put it on the li tag
    // done beacuse there is a border beneath the a we don't want to extend beyond it
    padding: 0;

    font-family: ${props => props.theme.typography.fontFamily};
    font-weight: bold;
    min-height: 32px;
    font-size: .9rem;
    text-transform: uppercase;
    color: inherit;

    // set a color above and below (for flex align middle), that is by default white.
    border-top: 5px solid white;
    border-bottom: 5px solid white;

    &.uk-active,
    &:hover {

      // then, on hover (or active), show the border color.
      color: inherit;
      border-bottom-color: initial;
    }
  }
  .uk-navbar-nav > li {
    // Remove padding from the a tag, so we can put it on the li tag
    // done beacuse there is a border beneath the a we don't want to extend beyond it
    padding: 0 15px;
    white-space: nowrap;
  }

  .uk-drop-bottom-center {
    /**
     * Remove the 20px margin that normally appears between the trigger and the dropdown.
     * @type {Number}
     */
    margin-top: 0;
  }

  .navbar-container {
    padding: 30px;
  }

  .quote {
    position: relative;
    &::before {
      content: "“";
      font-size: 180px;
      position: absolute;
      font-family: rift-soft, Helvetica, Arial, sans-serif; 
      font-style: normal;
      font-weight: bold;
      left: -5px;
      top: -80px;
    }
    padding-top: 80px;
    font-size: 1.3rem;
    font-style: italic;
  }

  callout {
    display: block;
    border-radius: 5px;
    padding: 30px;
    /* border: 2px solid white; */
    background: rgba(255, 255, 255, 0.2);
    /* font-size: 120%; */
  }

  .uk-lightbox-items {
    .uk-active.uk-transition-active {
      background: #857097;
    }
  }
  .uk-lightbox {
    background: #857097 !important;
  }
  @media screen and (max-width: 959px) {
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2rem;
    }
  }
`;

export default GlobalStyle;