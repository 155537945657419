import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import SocialIcon from "../atoms/SocialIcon"
import SubscribeButton from "../atoms/SubscribeButton"
import styled from "styled-components"

const Navbar = () => (
  <Nav className="uk-width-expand" data-uk-navbar>
    <div className="uk-navbar-center">
      <ul className="uk-navbar-nav">
        <li className="uk-hidden@l">
          <PartialNavLink className="uk-navbar-toggle"><span data-uk-navbar-toggle-icon></span><span className="uk-margin-small-left">Menu</span></PartialNavLink>
          <div className="uk-navbar-dropdown" data-uk-drop="boundary: .navbar-container; boundary-align: true; pos: bottom-justify;">
            <ul className="uk-nav uk-navbar-dropdown-nav">
              {/*<li className="color-about"><PartialNavLink to="/about">About</PartialNavLink></li>*/}
              <li className="color-leaders"><PartialNavLink to="/leaders">Leaders</PartialNavLink></li>
              <li className="color-advocates"><PartialNavLink to="/advocates">Advocates</PartialNavLink></li>
              <li className="color-incubators"><PartialNavLink to="/incubators">Incubators</PartialNavLink></li>
              <li className="color-about"><AnchorLink to="/leaders#staff">Staff</AnchorLink></li>
              <li className="color-research"><PartialNavLink to="/research">Research</PartialNavLink></li>
              <li className="color-training"><PartialNavLink to="/leadership-learning">Leadership &amp; Learning</PartialNavLink></li>
              <li className="color-events"><PartialNavLink to="https://bridge2023.wearebridge.com/">Events</PartialNavLink></li>
              <li className="color-partners"><PartialNavLink to="/partners">Partners</PartialNavLink></li>
              <li className="color-academy"><PartialNavLink to="/academy">Academy</PartialNavLink></li>
              <li className="color-about"><PartialNavLink to="/how-to-join">How to Join</PartialNavLink></li>
              <li className="">
                <div style={{textAlign: 'center'}}>
                  <SocialIcon 
                    link="https://www.facebook.com/wearebridge.strongertogether"
                    src="ico-fb.svg"
                    alt="Facebook"/>
                  <SocialIcon 
                    link="https://twitter.com/@wearebridge_"
                    src="ico-tw.svg"
                    alt="Twitter"/>
                  <SocialIcon 
                    link="https://www.instagram.com/weare_bridge/"
                    src="ico-ig.svg"
                    alt="Instagram"/>
                  <SocialIcon 
                    link="https://www.linkedin.com/company/bridgethegap-co/"
                    src="ico-in.svg"
                    alt="LinkedIn"/>
                  <div style={{marginTop: '10px'}}>
                    <SubscribeButton/>
                  </div>
                </div>
              </li>
            </ul>  
          </div>
        </li>
        {/*<li className="uk-visible@l color-about"><PartialNavLink to="/about">About</PartialNavLink></li>*/}
        <li className="uk-visible@l color-leaders">
          <PartialNavLink to="/leaders">Leaders</PartialNavLink>
          <div className="uk-navbar-dropdown">
            <ul className="uk-nav uk-navbar-dropdown-nav">
              <li className="uk-visible@l color-leaders"><PartialNavLink to="/leaders">Leaders</PartialNavLink></li>
              <li className="uk-visible@l color-advocates"><PartialNavLink to="/advocates">Advocates</PartialNavLink></li>
              <li className="uk-visible@l color-incubators"><PartialNavLink to="/incubators">Incubators</PartialNavLink></li>
              <li className="uk-visible@l color-about"><AnchorLink to="/leaders#staff">Staff</AnchorLink></li>
            </ul>
          </div>
        </li>
        
        <li className="uk-visible@l color-research"><PartialNavLink to="/research">Research</PartialNavLink></li>
        <li className="uk-visible@l color-training"><PartialNavLink to="/leadership-learning">Leadership &amp; Learning</PartialNavLink></li>
        <li className="uk-visible@l color-events"><PartialNavLink to="https://bridge2023.wearebridge.com/">Events</PartialNavLink></li>
        <li className="uk-visible@l color-partners"><PartialNavLink to="/partners">Partners</PartialNavLink></li>
        <li className="uk-visible@l color-academy"><PartialNavLink to="/academy">Academy</PartialNavLink></li>
        <li className="uk-visible@l color-about"><PartialNavLink to="/how-to-join">How to Join</PartialNavLink></li>
      </ul>
    </div>
  </Nav>
)

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? { className: "uk-active" }
    : {}
}

const PartialNavLink = props => (
  <Link getProps={isPartiallyActive} {...props} />
)

const Nav = styled.nav`
  // @media screen and (min-width: 640px) {
  //   margin-left: 15px;
  // }
`;

export default Navbar
