import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import logo from "../../assets/images/logo.svg"
import styled from "styled-components"

const Logo = ({siteTitle}) => (
  <Link
    to="/"
    style={{
      display: `flex`,
      textDecoration: `none`,
      height: `58px`,
      minHeight: `58px`,
    }}
  >
    <Image 
      src={logo} 
      alt={siteTitle}
      width="160"
    />
  </Link>
)

Logo.propTypes = {
  siteTitle: PropTypes.string,
}

Logo.defaultProps = {
  siteTitle: ``,
}

const Image = styled.img`
  width: 160px;
  transition: width 0.4s;
  @media screen and (max-width: 639px) {
    width: 80px;
  }
`;

export default Logo
