import React from "react"
import Logo from "../atoms/Logo"
// import Tagline from "../atoms/Tagline"
import SocialIcon from "../atoms/SocialIcon"
import SubscribeButton from "../atoms/SubscribeButton"
import ColorStripe from "../atoms/ColorStripe"
import Navbar from "./Navbar"
import styled from "styled-components"

const Header = () => (
  <header>
    <div className="uk-container uk-container-large uk-flex uk-padding navbar-container">
      <Logo siteTitle="BRIDGE" />
      <Navbar />
      <SocialIconWrapper className="uk-visible@m uk-flex-inline uk-flex-center uk-flex-middle">
        <SocialIcon 
          link="https://www.facebook.com/wearebridge.strongertogether"
          src="ico-fb.svg"
          alt="Facebook"/>
        <SocialIcon 
          link="https://twitter.com/@wearebridge_"
          src="ico-tw.svg"
          alt="Twitter"/>
        <SocialIcon 
          link="https://www.instagram.com/weare_bridge/"
          src="ico-ig.svg"
          alt="Instagram"/>
        <SocialIcon 
          link="https://www.linkedin.com/company/bridgethegap-co/"
          src="ico-in.svg"
          alt="LinkedIn"/>
        <SubscribeButton/>
      </SocialIconWrapper>
    </div>
    <ColorStripe />
  </header>
)

export default Header

const SocialIconWrapper = styled.div`
  // margin-top: 1.3rem;
  // margin-bottom: 2rem;
  // padding-bottom: 3rem;
  // @media screen and (min-width: 640px) {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   padding-right: 15px;
  // }
`;
