export const theme = {
  colors: {

    black: '#343434',
    white: '#ffffff',
    gray: '#9c9c9c',
    lightGray: '#eceff4',
    purple: '#4f3368',
    fuchsia: '#d4145a',
    darkFuchsia: '#9a1043',
    green: '#57744b',
    lightPurple: '#857097',
    yellow: '#ef9b36',
    orange: '#e14402',
    teal: '#0E969B',
    blue: '#2074AD',
    lightGreen: '#95af11',
    darkBlue: '#141141',

    // Links
    link: '#4f3368',
    visited: '#857097',

    // Accessibility and forms
    focus: '#4f3368',
    error: '#d4145a',
  },
  breakpoints: {
    sm: 'min-width: 576px',
    md: 'min-width: 768px',
    lg: 'min-width: 992px',
    xl: 'min-width: 1200px',
  },
  typography: {
    base: 1,
    scale: {
      xs: 1.667,
      sm: 1.797,
      md: 1.725,
      lg: 1.625,
    },
    fontFamily: "'Roboto Condensed', Helvetica, Arial, sans-serif",
  },
  typeStyles: ( exp ) => {
    return `font-size: ${theme.typography.base * Math.pow( theme.typography.scale.xs, exp )}rem;
    @media ( ${theme.breakpoints.sm} ) {
      font-size: ${theme.typography.base * Math.pow( theme.typography.scale.sm, exp )}rem;
    }
    @media ( ${theme.breakpoints.md} ) {
      font-size: ${theme.typography.base * Math.pow( theme.typography.scale.md, exp )}rem;
    }
    @media ( ${theme.breakpoints.lg} ) {
      font-size: ${theme.typography.base * Math.pow( theme.typography.scale.lg, exp )}rem;
    }`
  },
};