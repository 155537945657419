import React from "react"
import { OutboundLink } from "gatsby-plugin-gtag"
import styled from "styled-components"

const SubscribeButton = () => {

  return (
    <>
      <OutboundLink href={`https://www.smartbrief.com/signupSystem/subscribe.action?campaign=09ed8af4&pageSequence=1&briefName=bridge`} target="_blank">
        <Button>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '5px'}}>
            <span data-uk-icon="icon: mail" alt="Subscribe to BRIDGE Smartbrief"></span>
            <span className={`desktop-only`}>Subscribe</span>
          </div>
        </Button>
      </OutboundLink>
    </>
  )
  
}

const Button = styled.div`
  margin-left: 10px;
  border: 1px solid black;
  border-color: ${props => props.theme.colors.purple};
  border-radius: 1000px;
  padding: 0 1.5rem;
  margin-top: 4px;
  font-size: 14px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  &:hover {
    background-color: ${props => props.theme.colors.purple};
    color: white;
    text-decoration: none;
  }
`

export default SubscribeButton