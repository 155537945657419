import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-gtag"
import PropTypes from "prop-types"
import styled from "styled-components"
import { find, propEq } from "ramda"

const SocialIcon = ({ link, src, alt }) => {

  // egads. staticQuery doesn't support
  // passed variables. we have to query
  // for all icons and then filter them later.
  const data = useStaticQuery(graphql`
    query getSocialIcon {
      socialIcon: allFile(filter: {relativePath: {regex: "/ico-[a-z]{2}.svg/"}}) {
        nodes {
          publicURL
          relativePath
        }
      }
    }
  `)

  // filter out the icon we want to display.
  const iconMatcher = find(propEq('relativePath', src));
  const item = iconMatcher(data.socialIcon.nodes);

  return (
    <>
      <OutboundLink href={link} target="_blank">
        <Icon data-uk-svg src={item?.publicURL} alt={alt} />
      </OutboundLink>
    </>
  )
  
}

const Icon = styled.img`
  width: 35px;
  height: 35px;
  margin: 0 7px;
  transition: margin 0.3s;
  &:hover {
    margin-bottom: 4px;
    margin-top: -4px;
  }
`

SocialIcon.propTypes = {
  link: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string
}

export default SocialIcon
