import React from "react"
import styled from "styled-components"

const ColorStripe = () => (
  <div className="uk-flex">
    <Div className="uk-flex-1 bg-purple"></Div>
    <Div className="uk-flex-1 bg-fuchsia"></Div>
    <Div className="uk-flex-1 bg-green"></Div>
    <Div className="uk-flex-1 bg-teal"></Div>
    <Div className="uk-flex-1 bg-orange"></Div>
    <Div className="uk-flex-1 bg-yellow"></Div>
    <Div className="uk-flex-1 bg-light-purple"></Div>
    <Div className="uk-flex-1 bg-blue"></Div>
    <Div className="uk-flex-1 bg-light-green"></Div>
  </div>
)

const Div = styled.div`
  height: 12px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`;

export default ColorStripe
